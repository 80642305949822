<template>
	<header class="masthead" :style="{'max-height':mheight}">
		<div class="main-background" :style="{'max-height':mheight}"><div class="dots"></div></div>
			<div class="container-fluid h-100 align-items-center main-background-container" :style="{'display':visible}">
		      <div class="row masthead-row">
		        <div class="col-12">
		          <div class="mx-auto text-center " data-aos="fade-down"> 
		            <!-- <h1 class="mx-auto my-0 text-uppercase lg">STALMAT</h1>  -->
		            <h2 class="mx-auto my-0 pt-3 lg">{{ $t('top1') }}</h2>
		            <h2 class="mx-auto my-0 lg">{{ $t('top2') }}</h2>     
		          </div>     
		        </div>
		      </div>		     
		 </div>
	</header>
</template>
<script>
import i18n from '@/plugins/i18n';
// @ is an alias to /src
export default {
  	name: 'Masthead',
  	props: ['height','noLetters'],
  	data: function () {
	  return {
	  	mheight: 550,
	  	visible:'block',
	  }
	},
	mounted: function(){
		this.mheight = this.height + 'px';
		if(this.noLetters == true){
			this.visible = 'none';
		}
  	}
}
</script>