<template>
  <div id="page-top">
  	<div class="wrapper">
  		<div class="main">
		  	<Loader ref="loaderElement" v-if="loadingContent" />
			<Navbar/>
		    <router-view/>
		</div>
	</div>
    <Footer />
  </div>
</template>
<style lang="scss">

</style>
<script>
import Navbar from '@/components/Navbar.vue';
import Loader from '@/components/Loader.vue';
import Footer from '@/components/Footer.vue';
import store from '@/store';

export default {
	name: 'App',
	components: {
	Navbar,Loader,Footer
	},
	data () {
    	return {
    		site : {}
   		}
	},
	computed: {
		loadingContent(){
			return store.state.loadingContent
		}
	},
	methods:{
     	navbarCollapse: function() {
		    var scrolled = document.querySelector("body").getBoundingClientRect().top;
		    var element = document.getElementById("mainNav");    	
		    if (scrolled < -100 ) {		
		    	if(element != undefined){
		    		element.classList.add("navbar-shrink");
		    	}	   				      	
		    } else {
		    	if(element != undefined){
		    		element.classList.remove("navbar-shrink");
		    	}		      	
		    }
		},
		async getpage(){                       
	        this.site = await this.$store.dispatch("app/get",1);
	        console.log(this.site);
	    }
 	},
	created: function () {
		this.getpage();
		window.addEventListener('scroll', this.navbarCollapse);
	},
	destroyed () {
    	window.removeEventListener('scroll', this.navbarCollapse);
  	},
	mounted(){
		this.navbarCollapse();
	}
}
</script>