 <template> 
  <footer class="small footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 text-center text-md-left">
          <h6>{{ $t('Contact') }}</h6>
          <p>STALMAT Daria Walczak</p>
          <p><a href="tel:669220424">Tel: <span style="color:#226ddb">669 220 424</span></a> <a href="mailto:biuro@stalmat.eu">e-mail: <span style="color:#226ddb">biuro@stalmat.eu</span></a></p>
        </div>
        <div class="col-12 col-md-6 text-center text-md-right ">
          <a  href="https://itfrog.pl">{{ $t('CreatedBy') }} <span class="itfrog">It Frog</span> {{currentDate}}</a>
        </div>
      </div>
      
    </div>
  </footer>
 </template>
<script>

export default {
  name: 'Footer',
  components: {
  },
  data: function () {
	  return {
	    currentDate: new Date().getFullYear()
	  }
	},
}
</script>