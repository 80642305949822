import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import app from './modules/app'
const store = new Vuex.Store({
    state: {
      loadingContent: false
    },
    mutations: {
      loading(state,loading){
          state.loadingContent = loading;
      },
    },
    actions: {

    },
    modules: {
      app
    }
});

export default store;