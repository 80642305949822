<template>
  <div class="home">
    	<Masthead :height="500" id="about"/>
	  	<section class="about">
		    <div class="container">
		      <div class="row">
		        <div class="col-12 col-md-5 pl-3 text-left">
		          
		        </div>
		      </div>
		      <div class="row">
		        <div class="col-12 col-md-5 pt-4 pb-4 text-center">
		          <h2 class="text-left text-white" style="border-bottom: 1px solid black;">{{ $t('ABOUT') }}</h2>
		          <img src="@/assets/img/main2.jpg" alt="loader" class="img-fluid pt-2 d-none d-md-block"  data-aos="fade-up">
		        </div>
		        <div class="col-12 col-md-5 pt-4 offset-md-1 text-white" data-aos="fade-up">
		          <p style="text-align: justify;" v-html="$t('txt1')"></p>
		        </div>
		      </div>
		    </div>
	  	</section>
  </div>
</template>

<script>
// @ is an alias to /src
import Masthead from '@/components/Masthead.vue';

export default {
  name: 'Home',
  data: function () {
  return {
      title:this._i18n.t('StalmatOffer')
    }
  },
  metaInfo() {
  	return{
  		title: this._i18n.t('StalmatHome'),
	    meta: [
		      { 
		      	name: 'description', 
		      	content: 'STALMAT - Firma STALMAT powstała w 2005 roku. Głównym przedmiotem naszej działalności jest produkcja najwyższej jakości śrubowych elementów złącznych wykonanych według norm oraz dostarczonej dokumentacji i rysunków' 
		      },
		      { 
		      	name: 'keywords', 
		      	content: 'stalmat,śruby,śruby dwustronne,nakrętki,podkładki,sworznie' 
		      },
		      { 
		      	name: 'author', 
		      	content: 'https://www.itfrog.pl' 
		      },
		      { 
		      	name: 'robots', 
		      	content: 'index,follow' 
		      }
		    ]
  	}
  },
  components: {
    Masthead
  },
  created: function(){
  	//console.log(this);
  	//console.log(this._i18n.t('StalmatOffer'))
  	
  }
}
</script>
