import Vue from 'vue'
import Vuex from 'vuex';
import Ajax from '@/lib/ajax'
Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    title:'',
    text:'',
    id:'',
    imgSrc:'',
  },
  mutations: {
  },
  actions: {
    showItem(item,payload){
        this.title = payload.title;
        this.text = payload.text;
        this.id = payload.id;
        this.imgSrc = payload.imgSrc;
    }, 
    async get(){
        let reponse =await Ajax.get('/api/services/app/Page/Get?Id=2');
        console.log(reponse);
        return reponse.data.result;
    }
  },
  modules: {
  }
}
