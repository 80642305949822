<template>
	<div class="loader-container">
	    <div class="loader-logo">
	      <img src="@/assets/img/logo-icon_256.png" alt="loader" ref="logo-img" style="height:182px;width:256px;">
	    </div>
	</div>
</template>
<script>
// @ is an alias to /src
export default {
  	name: 'Loader',
  	mounted: function () {
		var loader = this.$refs['logo-img'];
		Loadgo.init(loader, {
		    'opacity':    0.2,
		    'animated':   false,
		    'bgcolor':    '#226ddb'
		});  
		Loadgo.loop(loader, 10);
		setTimeout( function(){
			Loadgo.stop(loader);
		},100);
	}
}
</script>