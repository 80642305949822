import Vue from 'vue';
import VueI18n from 'vue-i18n';
import pl from '@/translations/lang/pl.json';
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@/constants/trans'
Vue.use(VueI18n);
//let lang = window.navigator.language.slice(0, 2);
export const i18n = new VueI18n({ 
	locale: DEFAULT_LANGUAGE, 
	fallbackLocale: FALLBACK_LANGUAGE,  
	messages: { pl }
});
export default i18n;