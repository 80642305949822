import Vue from 'vue'
import App from './App.vue'
import { i18n } from '@/plugins/i18n'
import { Trans } from './plugins/Translation'
import router from './router'
import store from './store'
import axios from 'axios';
import VueAxios from 'vue-axios'
import Vuelidate from 'vuelidate'

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

Vue.config.productionTip = false

import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope,faPhone,faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faEnvelope,faPhone,faBars)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import 'bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/_main.scss';
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.use(VueAxios, axios)
Vue.use(Vuelidate)

window.$ = require('jquery')
// window.JQuery = require('jquery')
require('@/vendor/loadgo.js');
new Vue({
  render: h => h(App),
  router,
  i18n,
  store,
  components: { App },
  created () {
    AOS.init()
  } 
}).$mount('#app')
