<template>
	<div>
		<section id="_beforeNav" class="beforeNav h-card d-none d-lg-block">
		    <div class="container">
		    	<div class="row">
			      <div class="col-4 p-0">
			        <div class="logo-cont">
			          <span class="icon icon-lg icon-logo"></span> 
			        </div>
			      </div>
				  	<div class="col-4 p-0">
					<br>
					<p>PED 2014/68/E</p>
					<p>ISO 9001:2015</p>
					</div>	  
			  	</div>
		    </div>
		</section>
		<nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav" data-spy="affix" data-offset-top="50">
		    <div class="container">		      
		      <a class="navbar-brand text-white d-block d-sm-none" href="#"><img src="@/assets/img/logo-icon_128.png" alt="loader" class="icon-sm"><span style="top: 2px;left: 3px;position: relative;">TALMAT</span></a>
		      <a class="js-scroll-trigger" href="#page-top"></a>
		      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
		        <font-awesome-icon icon="bars"/>
		      </button>
		      <div class="collapse navbar-collapse" id="navbarResponsive">
		        <ul class="navbar-nav">
		          <li class="nav-item">
		          	<router-link :to="$i18nRoute({ name: 'home'})" href="#about" class="nav-link js-scroll-trigger" data-toggle="collapse" data-target=".navbar-collapse.show">{{ $t('about') }}</router-link>
		          </li>
		          <li class="nav-item">
		            <router-link :to="$i18nRoute({ name: 'offer'})" href="#offer" class="nav-link js-scroll-trigger" data-toggle="collapse" data-target=".navbar-collapse.show">{{ $t('offer') }}</router-link>
		          </li>
		          <li class="nav-item">
		            <router-link :to="$i18nRoute({ name: 'certificates'})" href="#certificates" class="nav-link js-scroll-trigger" data-toggle="collapse" data-target=".navbar-collapse.show">{{ $t('certificates') }}</router-link>
		          </li>
		          <li class="nav-item">
		            <router-link :to="$i18nRoute({ name: 'contact'})" href="#contact" class="nav-link js-scroll-trigger" data-toggle="collapse" data-target=".navbar-collapse.show">{{ $t('contact') }}</router-link>
		          </li>
		        </ul>
		        <ul class="navbar-nav ml-auto">
		          <li class="nav-item d-none d-md-block">   
		            <a href="tel:669220424">       
		              <font-awesome-icon icon="phone" class="icon-nav"/>
		            </a>
		          </li>
		          <li class="nav-item text-white">   
		            <div class="nav-link-single">  
		                <p class="nav-text"><a class="text-white" href="tel:669220424">tel. 669-220-424</a></p>
		            </div>
		          </li>
		          <li class="nav-item d-none d-md-block">   
		            <a href="mailto:biuro@stalmat.eu">       
		              <font-awesome-icon icon="envelope" class="icon-nav"/>
		            </a>
		          </li>
		          <li class="nav-item text-white">   
		            <div class="nav-link-single">  
		                <a class="nav-link-opp" href="mailto:biuro@stalmat.eu">   
		                <p class="nav-text"><a class="text-white" href="mailto:biuro@stalmat.eu">biuro@stalmat.eu</a></p>
		              </a>
		            </div>
		          </li>
		          <!-- <li class="nav-item">   
		            <a href='https://google.com/maps/?q=52.7495618,19.7508451' target="_blank">       
		              <i class="fas fa-map-marked-alt icon-nav"></i>
		            </a>
		          </li>
		          <li class="nav-item text-white">   
		            <div class="nav-link-db">  
		              <a class="nav-link-opp" href='https://google.com/maps/?q=52.7495618,19.7508451' target="_blank">   
		                <p class="nav-text">ZBÓJNO 7A,<br>09-213 GOZDOWO</p>
		              </a>
		            </div>
		          </li> -->
		          <li class="nav-item">	          	
		            <a href="#" class="nav-link mx-2 icon-cont" >
		            	<span  @click="changeLanguageByIcon('pl')" v-if="isCurrentLanguage('en')">
		              		<img src="@/assets/img/pl.png" alt="loader" class="icon icon-sm" >
		              	</span>
		              	<span  @click="changeLanguageByIcon('en')" v-if="isCurrentLanguage('pl')">
		             	 	<img src="@/assets/img/gb.png" alt="loader" class="icon icon-sm" >
		             	</span>
		            </a>
					<!-- <select
					    class="LanguageSwitcher"
					    name="language"
					    @change="changeLanguage"
					  >
					    <option
					      v-for="lang in supportedLanguages"
					      :key="lang"
					      :selected="isCurrentLanguage(lang)"
					      :class="{ 'is-selected': isCurrentLanguage(lang) }"
					      :value="lang"
					    >
					      {{lang}}
					    </option>
					  </select> -->
		          </li>
		        </ul>
		      </div>
		    </div>
		</nav>
	</div>
</template>

<script>
import i18n from '@/plugins/i18n';
import { Trans } from '@/plugins/Translation'
// @ is an alias to /src
export default {
  name: 'Navbar',
  data: function () {
	  return {
	    lang: 'en'
	  }
	},
  computed: {
    supportedLanguages () {
      return Trans.supportedLanguages
    },
    currentLanguage () {
      return Trans.currentLanguage
    }
  },
  methods: {    
  	changeLanguage (e) {
      const lang = e.target.value
      const to = this.$router.resolve({ params: { lang } })
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location)
      })
    },
    changeLanguageByIcon (lang) {
      const to = this.$router.resolve({ params: { lang } })
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location)
      })
    },
    isCurrentLanguage (lang) {
      return lang === this.currentLanguage
    }
  }
}
</script>