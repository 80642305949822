import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store';
import { apiHost } from '@/config';
import i18n from '@/plugins/i18n';
import Meta from 'vue-meta'
import { Trans } from '@/plugins/Translation'

function load (component) {
  return () => import('../views/'+component+'.vue')
}

Vue.use(Router)
Vue.use(Meta)

const routes = [
  {
    path: '/:lang',
    component: {
      template: '<router-view></router-view>'
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: '',
        name: 'home',
        component: require('../views/Home').default
      },
      {
        path: 'offer',
        name: 'offer',
        component: load('Offer')
      },
      {
        path: 'certificates',
        name: 'certificates',
        component: load('Certificates')
      },
      {
        path: 'contact',
        name: 'contact',
        component: load('Contact')
      },
      {
        path: 'item/:id',
        name: 'item',
        component: load('OfferItem')
      },
      {
        path: '*',
        component: load('404')
      }
    ]
  },
  {
    // Redirect user to supported lang version.
    path: '*',
    redirect (to) {
      return Trans.getUserSupportedLang()
    }
  }

]

const router = new Router({
  base:'/',
  mode: 'history',
  routes
})
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  store.commit('loading', true);
  next();
});
router.afterEach((to, from, next) => {
  setTimeout( function(){
     store.commit('loading', false);
  }, 100)
});
export default router
